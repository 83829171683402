<template>
  <div class="content">
    <div class="m-card bind-main">
      <div class="bind-list" v-if="false">
        <div class="bind-list-l">
          <svg class="icon-svg icon" aria-hidden="true">
            <use xlink:href="#icon-weixin2"></use>
          </svg>
          <div class="bind-list-l-right">
            <span>Wechat</span>
            <p v-if="!hasWeixin">
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-j"></use>
              </svg>
              未绑定
            </p>
            <p v-else>
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-r"></use>
              </svg>
              已绑定
            </p>
          </div>
        </div>
        <template v-if="hasWeixin">
          <Poptip confirm title="是否确认解绑?" @on-ok="delAuthInfo('Weixin')">
            <Button>解绑</Button>
          </Poptip>
        </template>
        <template v-else>
          <Button type="primary" @click.native="signinRedirect('Weixin')">绑定</Button>
        </template>
      </div>

      <div class="bind-list">
        <div class="bind-list-l">
          <!-- <svg class="icon-svg icon" aria-hidden="true">
            <use xlink:href="#icon-dianzan"></use>
          </svg> -->
          <i class="icon iconfont icon-lingying" style="color:#0077d5"></i>
          <div class="bind-list-l-right">
            <span>LinkedIn</span>
            <p v-if="!hasLinkedIn">
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-j"></use>
              </svg>
              Unbound
            </p>
            <p v-else>
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-r"></use>
              </svg>
              Bound
            </p>
          </div>
        </div>
        <template v-if="hasLinkedIn">
          <Poptip confirm title="Are you sure to untie?" @on-ok="delAuthInfo('LinkedIn')">
            <Button>Unbundling</Button>
          </Poptip>
        </template>
        <template v-else>
          <Button type="primary" @click.native="signinRedirect('LinkedIn')">Bind</Button>
        </template>
      </div>

      <div class="bind-list" v-if="false">
        <div class="bind-list-l">
          <svg class="icon-svg icon" aria-hidden="true">
            <use xlink:href="#icon-qq"></use>
          </svg>
          <div class="bind-list-l-right">
            <span>QQ</span>
            <p v-if="!hasQQ">
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-j"></use>
              </svg>
              未绑定
            </p>
            <p v-else>
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-r"></use>
              </svg>
              已绑定
            </p>
          </div>
        </div>
        <template v-if="hasQQ">
          <Poptip confirm title="是否确认解绑?" @on-ok="delAuthInfo('QQ')">
            <Button>解绑</Button>
          </Poptip>
        </template>
        <template v-else>
          <Button type="primary" @click.native="signinRedirect('QQ')">绑定</Button>
        </template>
      </div>

      <div class="bind-list" v-if="false">
        <div class="bind-list-l">
          <svg class="icon-svg icon" aria-hidden="true">
            <use xlink:href="#icon-twitter"></use>
          </svg>
          <div class="bind-list-l-right">
            <span>Twitter</span>
            <p v-if="!hasTwitter">
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-j"></use>
              </svg>
              未绑定
            </p>
            <p v-else>
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-r"></use>
              </svg>
              已绑定
            </p>
          </div>
        </div>
        <template v-if="hasTwitter">
          <Button @click="delAuthInfo('Twitter')">解绑</Button>
        </template>
        <template v-else>
          <Button type="primary" @click.native="signinRedirect('Twitter')">绑定</Button>
        </template>
      </div>

      <div class="bind-list" v-if="false">
        <div class="bind-list-l">
          <svg class="icon-svg icon" aria-hidden="true">
            <use xlink:href="#icon-facebook"></use>
          </svg>
          <div class="bind-list-l-right">
            <span>Facebook</span>
            <p v-if="!hasFacebook">
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-j"></use>
              </svg>
              Bound
            </p>
            <p v-else>
              <svg class="icon-svg icon-min" aria-hidden="true">
                <use xlink:href="#icon-r"></use>
              </svg>
              UnBound
            </p>
          </div>
        </div>
        <template v-if="hasFacebook">
          <Button @click="delAuthInfo('Facebook')">Unbundling</Button>
        </template>
        <template v-else>
          <Button type="primary" @click.native="signinRedirect('Facebook')">Bind</Button>
        </template>
      </div>
    </div>
    <Modal v-model="show" footer-hide>
      <div class="f-tac">
        <div><Icon type="ios-alert" size="80" color="#fe5e00" /></div>
        <p style="margin: 30px 0">This {{ showTxt }} has been bound with other user information</p>
        <div class="f-tac" style="margin: 10px">
          <Button style="width: 100px;margin-right: 20px" @click="show = false">Cancel</Button>
          <Button style="width: 100px;" type="primary" @click="show = false">Ok</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import config from "@assets/config.json"; // api 请求json

export default {
  name: "my-bindAuth",
  data() {
    return {
      show: false,
      showTxt: "",
      info: [],
    };
  },
  created() {
    this.init();
    this.addAuthHandle();
  },
  destroyed() {},
  computed: {
    ...mapGetters({
      userId: "getUserId",
    }),
    hasWeixin() {
      return this.info ? this.info.indexOf("Weixin") !== -1 : false;
    },
    hasQQ() {
      return this.info ? this.info.indexOf("QQ") !== -1 : false;
    },
    hasFacebook() {
      return this.info ? this.info.indexOf("Facebook") !== -1 : false;
    },
    hasTwitter() {
      return this.info ? this.info.indexOf("Twitter") !== -1 : false;
    },
    hasLinkedIn(){
      return this.info ? this.info.indexOf("LinkedIn") !== -1 : false;
    }
  },
  methods: {
    ...mapMutations({
      setRedirectUrl: "setRedirectUrl",
    }),
    ...mapActions({
      getAuthInfo: "user/getAuthInfo",
      delAuth: "user/delAuthInfo",
      oidcAuthWeixin: "oidcAuthWeixin/oidcAuthWeixin",
    }),

    addAuthHandle() {
      // 添加授权框显示事件绑定到window上 方便子窗口调用
      const that = this;
      window["authShow"] = (opt) => {
        if (opt.code && (opt.code === "other_bound" || opt.code === "bound")) {
          that.show = true;
          return;
        }
        if (opt && opt.result) {
          that.init();
        } else {
          that.$Message.success("Binding failed, please try again");
        }
      };
    },
    async init() {
      this.info = await this.getAuthInfo();
      console.log(this.info,'info');
    },
    async delAuthInfo(type) {
      const bool = await this.delAuth(type);
      if (bool && bool.result) {
        this.$Message.success("Unbound successfully");
        this.init();
      } else {
        this.$Message.error(bool.message);
      }
    },
    signinRedirect(type) {
      if (type == "QQ") {
        this.showTxt = "QQ";
      } else if (type == "Weixin") {
        this.showTxt = "微信";
      } else {
        this.showTxt = type;
      }
      this.setRedirectUrl(location.href);
      const reUrl = encodeURIComponent(`${location.origin}/auth-bind`);
      const url = `https://auth.zhanshangxiu.com/thirdpartybind?provider=${type}&returnUrl=${reUrl}&userId=${this.userId}`;
      this.ShowDialog(url);
    },
    ShowDialog(url) {
      var iWidth = 600; //窗口宽度
      var iHeight = 600; //窗口高度
      var iTop = (window.screen.height - iHeight) / 2;
      var iLeft = (window.screen.width - iWidth) / 2;
      window.open(url, "Detail", `Scrollbars=no,Toolbar=no,Location=no,Direction=no,Resizeable=no,Width=${iWidth} ,Height=${iHeight},top=${iTop},left=${iLeft}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
  .m-card {
    border: solid 1px #ededed;
    min-height: 700px;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}
.bind-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  padding: 24px 10px;
  border-bottom: 1px solid #e6e6e6;
  &-l {
    display: flex;
    align-items: center;
    .icon {
      font-size: 50px;
    }
    &-right {
      margin-left: 10px;
      span {
        font-size: 16px;
        color: #333;
      }
    }
  }
}
</style>
